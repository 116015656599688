import { BiScatterChart, BiLike, BiDislike, BiCross, BiSolidEdit} from "react-icons/bi";
import { TbTextPlus } from "react-icons/tb";
import React, { useState, useRef, useEffect } from 'react';

import {
    Box, Text, Flex, Input, Button, VStack, HStack, Heading,
    Container, useToast, IconButton, Tabs,Tab, TabList, TabPanels,TabPanel,
    Card,
    CardHeader,
} from "@chakra-ui/react";
import {
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Avatar,
} from '@chakra-ui/react'

import { FaAngleUp, FaMicrophone, FaMicrophoneSlash, FaStop } from 'react-icons/fa';
import axios from 'axios';
import { usePageColor } from '../contexts/ColorContext';
import LoadingOverlay from '../components/LoadingOverlayComponent';

import {newTheme} from '../components/mdstyle'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";


const getBaseUrl = () => {
    const env = process.env.NODE_ENV
    if(env === 'production') {
        return ''
    } else {
        return 'http://localhost:8000'
    }
}

const ServicePage = () => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isListening, setIsListening] = useState(false);
    const currentState = useState(0);
    const [loading, setLoading] = useState(false);
    const [decomposedMessages, setdecomposedMessages] = useState([]);
    const [extractedTopics, setExtractedTopics] = useState([]);
    const [intent, setIntent] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const chatInputRef = useRef(null);
    const messagesEndRef = useRef(null);
    const [triggerSendMessage, setTriggerSendMessage] = useState(false);
    const toast = useToast();
    const colors = usePageColor('rag');
    const [sessionId, setSessionId] = useState('');

    // MediaRecorder 및 오디오 관련 상태
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const audioRef = useRef(null);

    const steps = [
        { title: '1 단계', description: "정보 검색" },
        { title: '2 단계', description: "사업 신청" },
        { title: '3 단계', description: "이름 제공" },
        { title: '4 단계', description: "전화번호 제공" },
        ];
        
        const mapIntentToStep = (intent, prevStep) => {
        if (intent === '정보검색') return 1;
        if (intent === '사업신청') return 2;
        if (intent === '이름제공') return 3;
        if (intent === '전화번호제공') return 4;
        if (["긍정판단", "알수없음", "부정판단", "상세정보요청"].includes(intent)) return prevStep; 
        return 0; // 기본값
        };
        
        const [activeStep, setActiveStep] = useState(() => mapIntentToStep(intent, 0));  // 초기값을 콜백 함수로 전달
        
        useEffect(() => {
        setActiveStep(prevStep => mapIntentToStep(intent, prevStep));  // 이전 단계로 전달
        }, [intent]);


    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        if (triggerSendMessage) {
            handleSendTextMessage();
            setTriggerSendMessage(false); // 다시 false로 돌려놓기
        }
    }, [triggerSendMessage]);

    // 서버 요청 처리 함수
    const sendRequestToServer = async (url, data, headers) => {
        try {
            setLoading(true);
            const response = await axios.post(url, data, { headers });
            setLoading(false);
            return response.data;
        } catch (error) {
            console.error('Error sending message:', error);
            toast({
                title: "서버와의 통신 오류",
                description: "메시지를 전송하는 동안 문제가 발생했습니다.",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
            setLoading(false);
        }
    };

    // 메시지 업데이트 함수
    const updateChatMessages = (userMessage, aiMessage) => {
        setMessages(prev => [...prev, { role: 'user', content: userMessage }]);
        setMessages(prev => [...prev, { role: 'assistant', content: aiMessage }]);
    };

    // 인텐트 및 토픽 처리 함수
    const handleIntentAndTopics = (llm_response) => {
        const validIntents = ["정보검색", "사업신청", "상세정보요청", "이름제공", "전화번호제공", "긍정판단", "부정판단", "대화종료", "알수없음"];
        
        if (validIntents.includes(llm_response.intent)) {
            setSessionId(llm_response.session_id);
            setIntent(llm_response.intent);
            setExtractedTopics(llm_response.extracted_topics);
            setdecomposedMessages(llm_response.sub_topics_query);
        } else {
            setSessionId('');
            setIntent('');
            setExtractedTopics([]);
            setdecomposedMessages([]);
        }
    };


    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;  // 오디오를 처음으로 되돌림
            setIsPlaying(false);  // 재생 상태 업데이트
        }
    };

    const resetChatPage = () => {if (window.confirm("채팅을 초기화하시겠습니까?"))
        try {
            // 상태 초기화
            setMessages([]);
            setInputMessage('');
            setIsListening(false);
            setLoading(false);
            setExtractedTopics([]);
            setIntent('');
            setIsPlaying(false);
            setSessionId('');
    
            // MediaRecorder 및 오디오 관련 초기화
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
                mediaRecorderRef.current = null;
            }
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current = null;
            }
    
            // UI 스크롤 초기화
            window.scrollTo(0, 0);
    
            // 토스트 메시지로 초기화 완료 알림
            toast({
                title: "채팅이 초기화되었습니다.",
                status: "success",
                duration: 2000,
                isClosable: true,
                containerStyle: {
                    zIndex: 1500, // 이 값을 높게 설정하여 항상 최상단에 보이도록 함
                }
            });
        } catch (error) {
            console.error('Error resetting chat page:', error);
            toast({
                title: "초기화 오류",
                description: "채팅을 초기화하는 동안 문제가 발생했습니다.",
                status: "error",
                duration: 2000,
                isClosable: true,
                containerStyle: {
                    zIndex: 1500, // 이 값을 높게 설정하여 항상 최상단에 보이도록 함
                }
            });
        }
    };

    // 오디오 재생 함수
    const playAudioResponse = (audioBase64) => {
        try {
            const byteCharacters = atob(audioBase64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const audioBlob = new Blob([new Uint8Array(byteNumbers)], { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            audioRef.current = new Audio(audioUrl);
            audioRef.current.play();

            setIsPlaying(true); // 재생 상태 업데이트

            audioRef.current.onended = () => setIsPlaying(false);
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    };

    // 음성 메시지 전송 핸들러
    const handleSendVoiceMessage = async (audioBlob = null) => {
        if (audioBlob) {
            const formData = new FormData();
            formData.append('request', JSON.stringify({ method: "hyperclova_X", session_id: sessionId }));
            formData.append('file', audioBlob, 'recording.wav');

            const response = await sendRequestToServer(`${getBaseUrl()}/api/stt`, formData, {
                'Content-Type': 'multipart/form-data'
            });

            if (response) {
                const { llm_response, audio_response } = response;
                setSessionId(llm_response.session_id); 
                updateChatMessages(llm_response.user_message, llm_response.ai_message);
                handleIntentAndTopics(llm_response);
                if (audio_response) playAudioResponse(audio_response);
            }
        } else if (inputMessage.trim()) {
            handleSendTextMessage();
        }
    };

    // 텍스트 메시지 전송 핸들러
    const handleSendTextMessage = async () => {
        if (inputMessage.trim()) {
            const requestData = {
                method: "hyperclova_X",
                session_id: sessionId,
                query: inputMessage,
            };

            const response = await sendRequestToServer(`${getBaseUrl()}/api/chatting`, requestData, {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            });

            if (response) {
                const { llm_response } = response;
                setSessionId(llm_response.session_id); 
                updateChatMessages(inputMessage, llm_response.ai_message);
                handleIntentAndTopics(llm_response);
            }
        }
    };

    // 음성 입력 시작/중지 핸들러
    const handleVoiceInput = () => {
        setIsListening(prev => !prev);
        if (!isListening) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                mediaRecorderRef.current = new MediaRecorder(stream);
                audioChunksRef.current = [];

                mediaRecorderRef.current.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorderRef.current.onstop = () => {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    handleSendVoiceMessage(audioBlob);
                };

                mediaRecorderRef.current.start();
                toast({
                    title: "음성 인식 시작",
                    status: "info",
                    duration: 2000,
                    isClosable: true,
                    containerStyle: {
                        zIndex: 1500, // 이 값을 높게 설정하여 항상 최상단에 보이도록 함
                    }
                });

                setTimeout(() => {
                    if (mediaRecorderRef.current.state === 'recording') {
                        mediaRecorderRef.current.stop();
                        setIsListening(false);
                        toast({
                            title: "음성 인식 중지",
                            status: "warning",
                            duration: 2000,
                            isClosable: true,
                            containerStyle: {
                                zIndex: 1500, // 이 값을 높게 설정하여 항상 최상단에 보이도록 함
                            }
                        });
                    }
                }, 10000); // 10초 후 녹음 중지
            }).catch((error) => {
                console.error('Error accessing microphone:', error);
                toast({
                    title: "마이크 액세스 오류",
                    description: "마이크에 액세스하는 동안 문제가 발생했습니다.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                    containerStyle: {
                        zIndex: 1500, // 이 값을 높게 설정하여 항상 최상단에 보이도록 함
                    }
                });
            });
        } else {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
            }
            toast({
                title: "음성 인식 중지",
                status: "warning",
                duration: 2000,
                isClosable: true,
                containerStyle: {
                    zIndex: 1500, // 이 값을 높게 설정하여 항상 최상단에 보이도록 함
                }
            });
        }
    };

    // 버튼 표시 함수: 텍스트 입력 여부에 따라 다른 버튼을 렌더링
    const renderButtons = () => {
        if (inputMessage.trim()) {
            return (
                <Button 
                    type="submit" 
                    borderRadius="full"  // 더 둥근 테두리
                    isDisabled={loading} 
                    rightIcon={<FaAngleUp />} 
                    colorScheme="blue"  // 일관된 색상
                    size="md"  // 버튼 크기 조정
                    boxShadow="md"  // 그림자 추가로 더 입체감 있는 버튼
                    _hover={{ boxShadow: "lg", transform: "scale(1.05)" }}  // 호버 시 애니메이션 추가
                    transition="all 0.2s"
                >
                    Send
                </Button>
            );
        } else {
            return (
                <>
                    <IconButton
                        icon={isListening ? <FaMicrophoneSlash /> : <FaMicrophone />}
                        colorScheme={isListening ? "blue" : "gray"}
                        onClick={handleVoiceInput}
                        borderRadius="full"  // 둥근 테두리
                        size="md"  // 버튼 크기 조정
                        boxShadow="sm"
                        _hover={{ boxShadow: "md", transform: "scale(1.05)" }}  // 호버 시 애니메이션
                        transition="all 0.2s"
                    />
                    <IconButton
                        icon={<FaStop />}
                        colorScheme="red"
                        onClick={stopAudio}
                        isDisabled={!isPlaying}
                        borderRadius="full"
                        size="md"
                        boxShadow="sm"
                        _hover={{ boxShadow: "md", transform: "scale(1.05)" }}  // 호버 시 애니메이션
                        transition="all 0.2s"
                        ml={2}  // 약간의 간격 추가
                    />
                    <IconButton
                        icon={<BiSolidEdit />}
                        colorScheme="blue"
                        onClick={resetChatPage}
                        borderRadius="full"
                        size="md"
                        boxShadow="sm"
                        _hover={{ boxShadow: "md", transform: "scale(1.05)" }}  // 호버 시 애니메이션
                        transition="all 0.2s"
                        ml={2}  // 약간의 간격 추가
                    />
                </>
            );
        }
    };

    return (
        <Container maxW="container.md" h="90vh" py={5}>
            <Stepper size="md" colorScheme="red" index={activeStep} p={5}>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box flexShrink='0'>
                                <StepTitle>{step.title}</StepTitle>
                                <StepDescription>{step.description}</StepDescription>
                            </Box>

                            <StepSeparator />
                        </Step>
                        ))}
            </Stepper>
            <Flex h="full" gap={4}>
                <Flex direction="column" flex={1} borderWidth={2} borderRadius="16px" p={4} position='relative' bg={colors.ContainerMainBg}>
                    <VStack flex={1} overflowY="auto" spacing={4} align="stretch">
                        <LoadingOverlay isLoading={loading} bgColor={colors.ContainerMainBg}/>
                        {messages.map((message, index) => (
                            <Box 
                                key={index}
                                display="flex"
                                alignItems="flex-start"
                                flexDirection={message.role === 'assistant' ? 'row-reverse' : 'row'}
                                mb={4}  // 메시지 간의 여백 추가
                                >
                                {/* Avatar */}
                                <Avatar 
                                    name={message.role === 'assistant' ? 'Assistant' : 'User'} 
                                    src={message.role === 'assistant' 
                                    ? 'https://www.junggu.seoul.kr/chief/img/profile02.png'
                                    : 'https://png.pngtree.com/png-clipart/20191122/original/pngtree-user-icon-isolated-on-abstract-background-png-image_5192004.jpg'}
                                    size="md"  // 아바타 크기 조정
                                    mr={message.role !== 'assistant' ? 3 : 0}  // 간격 조정
                                    ml={message.role === 'assistant' ? 3 : 0}  // 간격 조정
                                />

                                {/* Message Box */}
                                <Box
                                    alignSelf={message.role === 'assistant' ? 'flex-end' : 'flex-start'}
                                    bg={message.role === 'assistant' ? 'blue.50' : 'gray.100'}  // 역할에 따라 다른 배경색
                                    color={message.role === 'assistant' ? 'blue.900' : 'gray.900'}  // 텍스트 색상
                                    py={2}
                                    px={4}
                                    borderRadius="16px"
                                    maxW="70%"
                                    shadow="md"
                                    animation="ease-in 0.2s"  // 애니메이션 추가
                                    _hover={{ boxShadow: "lg" }}  // 호버 시 상호작용 효과
                                >
                                    {message.role === 'assistant' ? (
                                    <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                        {message.content}
                                    </Markdown> 
                                    ) : (
                                    <Text>{message.content}</Text>
                                    )}
                                </Box>
                            </Box>
                        ))}
                        

                        {/* Intent가 '정보검색'일 때만 Extracted Topics 테이블 표시 */}
                        {intent === "정보검색" && extractedTopics?.length > 0 && decomposedMessages?.length > 0 && (
                        <Box as="form" width="100%" mt={4}>
                            <Flex alignItems="center" mb={4}>
                                <BiScatterChart size="25px" />
                                <Text fontWeight="bold" ml={2} fontSize="25">
                                    출처
                                </Text>
                            </Flex>
                            <Tabs 
                                isFitted 
                                variant="enclosed-colored"  // 탭에 색상을 적용한 스타일
                                bg="#f4f4f9" 
                                borderRadius="16px" 
                                size="md" 
                                colorScheme="gray"
                                position="relative"
                                >
                                {/* Tab 목록 */}
                                <TabList mb="1em">
                                    {extractedTopics.map((topic, index) => (
                                    <Tab 
                                        key={index} 
                                        fontWeight="bold" 
                                        _selected={{ bg: "gray.100", color: "gray.600" }}  // 선택된 탭 스타일
                                        _hover={{ bg: "gray.50", color: "gray.700" }}  // 호버 스타일
                                        borderRadius="full"  // 둥근 테두리로 부드러운 느낌
                                        p={3}  // 탭에 충분한 패딩 추가
                                    >
                                        {`${index + 1}. ${Object.values(topic)[0].split("- - -")[1]}`}
                                    </Tab>
                                    ))}
                                </TabList>

                                {/* Tab 패널 내용 */}
                                <TabPanels>
                                    {extractedTopics.map((topic, index) => {
                                    const value = Object.values(topic)[0];
                                    return (
                                        <TabPanel key={index}>
                                        <Box 
                                            borderRadius="16px" 
                                            bg="white"  // 깔끔한 흰색 배경
                                            p={6} 
                                            shadow="md" 
                                            borderWidth="1px" 
                                            borderColor="gray.200"  // 은은한 테두리 색상
                                            transition="box-shadow 0.3s ease"  // 애니메이션 추가
                                            _hover={{ boxShadow: "lg" }}  // 호버 시 그림자 강조
                                        >
                                            <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                            {value}
                                            </Markdown>
                                            <Flex justify="center" mt={4} gap={4}>
                                            <Button
                                                variant="solid"
                                                leftIcon={<BiLike />}
                                                position="relative"
                                                colorScheme="green"
                                                borderRadius="full"
                                                boxShadow="sm"
                                                _hover={{ boxShadow: "md", transform: "scale(1.05)" }}  // 버튼 상호작용 효과
                                                transition="all 0.2s"
                                                onClick={() => {
                                                toast({
                                                    title: '"좋아요" 버튼이 눌렸습니다.',
                                                    status: "success",
                                                    duration: 2000,
                                                    isClosable: true
                                                });
                                                }}>
                                                좋아요
                                            </Button>
                                            <Button 
                                                variant="solid"
                                                leftIcon={<BiDislike />}
                                                position="relative"
                                                colorScheme="red"
                                                borderRadius="full"
                                                boxShadow="sm"
                                                _hover={{ boxShadow: "md", transform: "scale(1.05)" }}  // 버튼 상호작용 효과
                                                transition="all 0.2s"
                                                onClick={() => {
                                                toast({
                                                    title: '"싫어요" 버튼이 눌렸습니다.',
                                                    status: "error",
                                                    duration: 2000,
                                                    isClosable: true
                                                });
                                                }}>
                                                싫어요
                                            </Button>
                                            </Flex>
                                        </Box>
                                        </TabPanel>
                                    );
                                    })}
                                </TabPanels>
                                </Tabs>
                            <Flex alignItems="center" mb={4} mt={4}>
                                <TbTextPlus size="25px" />
                                <Text fontWeight="bold" ml={2} fontSize="25">
                                    관련
                                </Text>
                            </Flex>
                            <VStack spacing={4} align="start">
                                {decomposedMessages?.map((message, index) => (
                                    <Card key={index} variant="elevated" width="100%" minHeight="50px">
                                        <CardHeader>
                                            <Flex alignItems="center" justifyContent="space-between">
                                                <Heading size='sm'>{message}</Heading>
                                                <BiCross 
                                                    onClick={() => { 
                                                        setInputMessage(message); // 먼저 메시지를 설정하고
                                                        setTriggerSendMessage(true); // 메시지 전송을 트리거
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </Flex>
                                        </CardHeader>
                                    </Card>
                                ))}
                            </VStack>
                    </Box>
                    )}

                        <div ref={messagesEndRef} />
                    </VStack>
                    <HStack 
                        as="form" 
                        onSubmit={(e) => { e.preventDefault(); handleSendTextMessage(); setInputMessage(''); }} 
                        mt={4}
                        spacing={3}  
                        backgroundColor="gray.50"
                        p={4}
                        borderRadius="full"
                        boxShadow="sm"
                        >
                        <Input
                            ref={chatInputRef}
                            placeholder="메시지를 입력하세요"
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            borderRadius="full"
                            paddingX={4}
                            paddingY={3}
                            backgroundColor="white"
                            boxShadow="sm"
                            border="1px"
                            borderColor="gray.300"
                            _focus={{
                            boxShadow: "outline",
                            borderColor: "blue.300",
                            ring: 1,
                            }}
                            transition="all 0.2s"
                        />
                        {renderButtons()}
                    </HStack>
                </Flex>
                {currentState === 10 && (
                    <Box flex={1} borderWidth={1} borderRadius="16px" p={4} overflowY="auto">
                        <Text fontWeight="bold" mb={2}>Service Detail Response</Text>
                        <Text>서비스 상세 정보가 여기에 표시됩니다.</Text>ㄴㅇ
                    </Box>
                )}
            </Flex>
        </Container>
    );
};

export default ServicePage;
