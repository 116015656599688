import Cookies from 'js-cookie';

const getBaseUrl = () => {
    const env = process.env.NODE_ENV
    if(env === 'production') {
        return ''
    } else {
        return 'http://localhost:8000'
    }
}


/////////////// 인증관련 API //////////////////
const getTokens = (type) => {
    if (type === 'refresh') {
        const refreshToken = localStorage.getItem('refresh-token');
        return {'refresh-token': refreshToken};
    } else if (type === 'access') {
        const accessToken = localStorage.getItem('access-token');
        return {'access-token': accessToken};
    }
}

const getRefresh = async () => {
    const refreshToken = getTokens("refresh");

    const apiUrl = `${getBaseUrl()}/api/auth/refresh`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken['refresh-token']}`
            },
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));

        return data;
    } catch (error) {
        throw error;
    }
};

const postSignIn = async (username, password) => {
    const apiUrl = `${getBaseUrl()}/api/auth/sign_in`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_name: username,
                user_password: password
            }),
            credentials: 'include'
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));
        
        return data;
    } catch (error) {
        throw error;
    }
};
//////////////// 인증관련 API 끝 //////////////////

/////////////// ChatbotPage /////////////////////
const postSTT = async (audioBlob, requestData) => {
    const apiUrl = `${getBaseUrl()}/api/stt/audio_to_text`;

    if (!(audioBlob instanceof Blob)) {
        throw new Error("audioBlob is not a Blob instance");
    }

    const formData = new FormData();
    formData.append("file", audioBlob);
    formData.append("item", JSON.stringify(requestData));


    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData
        });

        // response가 올바르게 수신되었는지 확인
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};



const getDomains = async () => {
    // const apiUrl = `${config.config.base_url}/api/domains`;
    const apiUrl = `${getBaseUrl()}/api/domains`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};


const getTemplates = async () => {
    const apiUrl = `${getBaseUrl()}/api/doc/templates`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const postRun = async function* (inputQuery, isBlogChecked, isNewsChecked, searchType) {
    
    const apiUrl = `${getBaseUrl()}/api/run`;
    const accessToken = getTokens('access');
    
    const headers = {
        'Content-Type': 'application/json'
    };
    
    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            query: inputQuery,
            blog_yn: isBlogChecked,
            news_yn: isNewsChecked,
            search_type: searchType
        }),
        credentials: 'include'
    });

    console.log(response)

    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        while (true) {
            const newlineIndex = buffer.indexOf('\n');
            if (newlineIndex === -1) break;

            const line = buffer.slice(0, newlineIndex);
            buffer = buffer.slice(newlineIndex + 1);

            if (line.trim()) {
                try {
                    let token = JSON.parse(line);
                    // console.log(token);
                    yield token;
                } catch (e) {
                    console.error('Error parsing JSON : ', e)
                }                    
            }
        }
    }
};


const postChunks = async (queryResponses) => {
    const apiUrl = `${getBaseUrl()}/api/get_chunks`;

    const chunkIds = queryResponses.chunk_ids || [];
    const fileIds = queryResponses.file_ids || [];

    if (!Array.isArray(chunkIds) || !Array.isArray(fileIds) || chunkIds.length !== fileIds.length) {
        throw new Error('chunk_ids and file_ids must be arrays of the same length');
    }
    
    let chunkRequestData = chunkIds.map((chunk_id, index) => {
        return {
            file_ids: fileIds[index],
            chunk_ids: chunk_id
        };
    });

    chunkRequestData = {"query": chunkRequestData};

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(chunkRequestData),
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const getDownloadFile = async (fileId) => {

    const apiUrl = `${getBaseUrl()}/api/download_file?file_id=${fileId}`;
    
    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        })
    
        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
            
        // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }
    
        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);
    
        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    
        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };

    } catch (error) {
        console.error('Error downloading the document:', error);
        throw error;
    }
}

/////////////// ChatbotPage 끝 /////////////////////

/////////////// RagPage /////////////////////
const getRags = async (currentUsage, activedDomainIndex, inputKeyword) => {
    const accessToken = getTokens("access");

    let stringfiedUsage = '';
    
    if (currentUsage === 0) {
        stringfiedUsage = 'rag'     
    } else if (currentUsage === 1) {
        stringfiedUsage = 'gen'
    }

    const apiUrl = `${getBaseUrl()}/api/rag?usage_id=${activedDomainIndex}&keyword=${encodeURIComponent(inputKeyword)}&usage=${stringfiedUsage}`;

    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const putRagOneRow = async (drawerValue, currentUsage) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag`;

    let stringfiedUsage = '';
    
    if (currentUsage === 0) {
        stringfiedUsage = 'rag'     
    } else if (currentUsage === 1) {
        stringfiedUsage = 'gen'
    }

    if (drawerValue.file_outline === null) {
        drawerValue.file_outline = '';
    }
    
    try {
        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            
            body: JSON.stringify({
                "file_id": drawerValue.file_id,
                "file_name": drawerValue.file_name,
                "usage": stringfiedUsage,
                "doc_outline": drawerValue.file_outline
            }),
        });

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const deleteRagOneRow = async (drawerValue, currentUsage) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag`;

    let stringfiedUsage = '';
    
    if (currentUsage === 0) {
        stringfiedUsage = 'rag'     
    } else if (currentUsage === 1) {
        stringfiedUsage = 'gen'
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            body: JSON.stringify({
                "file_id": drawerValue.file_id,
                "usage": stringfiedUsage
            }),
        });

        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
};

const postRagOneRow = async (modalValue, currentUsage) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag`;

    const formData = new FormData();

    let usage = '';
    if (currentUsage === 0) {
        usage = 'rag';
    } else if (currentUsage === 1) {
        usage = 'gen';
    }

    formData.append('usage_id', modalValue.categoryId);
    formData.append('file_name', modalValue.fileName);
    formData.append('usage_name', modalValue.categoryName);
    formData.append('file', modalValue.file);
    formData.append('usage', usage);
    formData.append('doc_outline', modalValue.fileOutline);
    formData.append('is_raptor', modalValue.isRaptor);
    formData.forEach((value, key) => {
        console.log(`${key} : ${value}`)
    });


    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

/////////////// RagPage 끝 /////////////////////

/////////////// DocPage 시작 /////////////////////

const postdocCreateIndex = async (document) => {
    const apiUrl = `${getBaseUrl()}/api/doc/create_index`;
    const accessToken = getTokens("access");

    const headers = {
        'Content-Type': 'application/json'
    };

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(document),
            credentials: 'include' // 이거 쿠키도 무조건 보내는거임
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};


const getDocGetContent = async (file_id, file_sub_id) => {
    const apiUrl = `${getBaseUrl()}/api/doc/get_content?doc_id=${file_id}&doc_sub_ids=${file_sub_id}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;    
    } catch (error) {
        throw error;
    }
}

const postDocDownload = async (file_id) => {
    const apiUrl = `${getBaseUrl()}/api/doc/download`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "doc_id": file_id,
                "file_type": "docx"
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
        
         // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }

        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);

        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }

        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };
    } catch (error) {
        console.error('Error downloading the document:', error);
        throw error;
    }
};

async function* postAssistantMessage(message, doc_sub_id, doc_sub_name, contents) {
    const apiUrl = `${getBaseUrl()}/api/doc/assistant`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: message,
            raptor_type: 'collapsed',
            doc_sub_name: doc_sub_name ? doc_sub_name : '',
            contents: contents ? contents : '',
        }),
    });

    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        while (true) {
            const newlineIndex = buffer.indexOf('\n');
            if (newlineIndex === -1) break;

            const line = buffer.slice(0, newlineIndex);
            buffer = buffer.slice(newlineIndex + 1);

            if (line.trim()) {
                try {
                    let token = JSON.parse(line);
                    yield token;
                } catch (e) {
                    console.error('Error parsing JSON:', e);
                }
            }
        }
    }
}


const putDocSave = async (document) => {
    const apiUrl = `${getBaseUrl()}/api/doc`;

    try {
        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(document),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}


const getDocHistory = async () => {
    const apiUrl = `${getBaseUrl()}/api/doc/history`;
    

    const accessToken = getTokens("access");

    const headers = {}

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }


    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const getDocMeta = async (file_id) => {
    const apiUrl = `${getBaseUrl()}/api/doc/doc_meta?doc_id=${file_id}`;
    const accessToken = getTokens("access");

    const headers = {
        'Content-Type': 'application/json'
    };

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }


    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

const deleteDocData = async (file_id) => {
    const apiUrl = `${getBaseUrl()}/api/doc?doc_id=${file_id}`;

    const headers = {
        'Content-Type': 'application/json'
    };

    const accessToken = getTokens("access");

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: headers,
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }

}

/////////////// DocPage 끝 /////////////////////

/////////////// Playground Page /////////////////classification
async function* postMultitask(userPrompt, userFile, currentNodeIndex, isDocumentSummaryTitleFixed) {
    let apiUrl;
    let formData = new FormData();

    if (currentNodeIndex === 1) {
        apiUrl = `${getBaseUrl()}/api/multitask/summary`;
    
        formData.append('user_prompt', userPrompt);
        formData.append('file', userFile.file);

    } else if (currentNodeIndex === 0){
        apiUrl = `${getBaseUrl()}/api/multitask/classification`;
        if (!userPrompt){
            throw new Error('채팅창이 비었습니다. 문서 유형을 입력해 주세요.')
        }
        formData.append('classification', userPrompt);
        formData.append('file', userFile.file);
    }

    console.log('-------------------------------')
    formData.forEach((value, key) => {
        console.log(`${key} : ${value}`)
    });

    const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
    });

    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        while (true) {
            const newlineIndex = buffer.indexOf('\n');
            if (newlineIndex === -1) break;

            const line = buffer.slice(0, newlineIndex);
            buffer = buffer.slice(newlineIndex + 1);

            if (line.trim()) {
                try {
                    let token = JSON.parse(line);
                    yield token;
                } catch (e) {
                    console.error('Error parsing JSON:', e);
                }
            }
        }
    }
}

const postMultitaskSummaryDocuments = async(userPrompt, userFile, isDocumentSummaryTitleFixed) => {
    let fileList = []
    let formData = new FormData();

    if (!isDocumentSummaryTitleFixed && !userPrompt) {
        throw new Error('문서 제목을 입력해 주세요.')
    }

    const apiUrl = `${getBaseUrl()}/api/multitask/create_report`;
    if (isDocumentSummaryTitleFixed) {
        userPrompt = '';
    }
    userFile.map((file)=>{
        fileList.push(file.file);
    })

    for (let i = 0; i < fileList.length; i++) {
        formData.append('files', fileList[i]);
    }
    formData.append('generate_title_with_ai', isDocumentSummaryTitleFixed);
    formData.append('report_title', userPrompt);

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData,
        });
        const data = await response.json();
        return data;
    } catch (error){
        console.log(error)
        throw error;
    }
}

async function* postMultitaskChat(userPrompt, thisChatThreadId) {

    const apiUrl = `${getBaseUrl()}/api/multitask/chat`;

    const headers = {
        'Content-Type': 'application/json'
    };
    const accessToken = getTokens("access");

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    if (thisChatThreadId !== '') {
        Cookies.set('chat_thread', thisChatThreadId, { expires: 365 });
    }

    const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify({
            query: userPrompt,
            raptor_type: "collapsed"
        }),
        headers: headers,
        credentials: 'include'
    });

    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        while (true) {
            const newlineIndex = buffer.indexOf('\n');
            if (newlineIndex === -1) break;

            const line = buffer.slice(0, newlineIndex);
            buffer = buffer.slice(newlineIndex + 1);

            if (line.trim()) {
                try {
                    let token = JSON.parse(line);
                    yield token;
                } catch (e) {
                    console.error('Error parsing JSON:', e);
                }
            }
        }
    }
}

const postMultitaskDownloadReport = async (reportTitle, reportOutline, markdownContent) => {
    const apiUrl = `${getBaseUrl()}/api/multitask/download_report`;
    const headers = {
        'Content-Type': 'application/json'
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({
                report_title: reportTitle,
                report_outline: reportOutline,
                markdown_content: markdownContent,
                file_type: "docx"  
            }),
            headers: headers,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
        
         // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }

        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);

        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }

        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };
    } catch (error) {
        console.error('Error downloading the document:', error);
        throw error;
    }

}

const getMultitaskChatHistory = async() => {
    const apiUrl = `${getBaseUrl()}/api/multitask/chat_history`;

    const accessToken = getTokens("access");

    const headers = {}

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
            credentials: 'include'
        });
        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
}

const getMultitaskThreadHistory = async(thisChatThreadId) => {
    const apiUrl = `${getBaseUrl()}/api/multitask/chat_thread`;

    const accessToken = getTokens("access");

    const headers = {}

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    if (thisChatThreadId !== '') {
        Cookies.set('chat_thread', thisChatThreadId, { expires: 365 });
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
            credentials: 'include'
        });
        const data = await response.json();
        console.log(response);
        return data;

    } catch (error) {
        throw error;
    }
}

/////////////// Playground Page 끝 /////////////////

export {
    getDomains,
    postRun,
    postChunks,
    getDownloadFile,
    postSTT,
    getRags,
    getTemplates,
    putRagOneRow,
    deleteRagOneRow,
    postRagOneRow,
    postSignIn,
    getRefresh,
    postdocCreateIndex,
    getDocGetContent,
    postDocDownload,
    postAssistantMessage,
    putDocSave,
    getDocHistory,
    getDocMeta,
    deleteDocData,
    postMultitask,
    postMultitaskChat,
    getMultitaskChatHistory,
    getMultitaskThreadHistory,
    postMultitaskSummaryDocuments,
    postMultitaskDownloadReport,
};
